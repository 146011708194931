import React from "react";

const Banner = props => {
  const { bannerImage, bannerHeading, bannerCopy } = props;

return (
  <div className="banner">
    <div className="banner--image-wrapper">
      <img src={bannerImage.url} alt={bannerImage.alt} />
    </div>
    <h4>{bannerHeading}</h4>
    <p>{bannerCopy}</p>
  </div>
  )
}

export default Banner;
