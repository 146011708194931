import React, { Fragment } from "react";
import { graphql } from "gatsby";
import Modal from "react-modal";

import Menu from "../components/menu";

import SectionTwo from "../templates/sectionTwo";
import SectionThree from "../templates/sectionThree";
import SectionFour from "../templates/sectionFour";
import SectionFive from "../templates/sectionFive";
import SectionSix from "../templates/sectionSix";
import SectionSeven from "../templates/sectionSeven";
import Footer from "../templates/footer";

import Video from "../assets/video.mp4";
import newVideo from "../assets/new-video.mp4";

const customStyles = {
  content: {
    padding: "40px"
  }
};

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { modalIsOpen: false };

    this.video = React.createRef();
    this.newVideo = React.createRef();
  }

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  afterOpenModal = () => {
    const { videoToPlay } = this.state;
    if (videoToPlay === "old") {
      this.video.current.play();
    } else {
      this.newVideo.current.play();
    }
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  render() {
    const { prismicBlogHome, prismicMenu, prismicFooter } = this.props.data;

    const { data } = prismicBlogHome;
    return (
      <Fragment>
        <div className="hero-block">
          <Menu menuItems={prismicMenu.data} />
          <div className="row center">
            <img src={data.hero_logo.url} alt={data.hero_logo.alt} />
          </div>
        </div>
        <div
          className="hero-block hero-block--desktop"
          style={{
            backgroundImage: `url('${data.hero_image.url}')`,
            backgroundSize: "cover"
          }}
        >
          <Menu menuItems={prismicMenu.data} />
          <div className="row center">
            <img src={data.hero_logo.url} alt={data.hero_logo.alt} />
            <button
              onClick={() => {
                this.openModal();
                this.setState({ videoToPlay: "new" });
              }}
            >
              {data.hero_button_text.text}
            </button>
            <button
              onClick={() => {
                this.openModal();
                this.setState({ videoToPlay: "old" });
              }}
            >{data.hero_button_2_text.text}</button>
          </div>
        </div>

        <SectionTwo data={data} />

        {/* The Tech */}
        <section
          className="section-3"
          id={prismicMenu.data.link_one.text.replace(" ", "-")}
        >
          <SectionThree data={data} />
        </section>

        {/* Benefits */}
        <section
          className="section-4"
          id={prismicMenu.data.link_two.text.replace(" ", "-")}
        >
          <SectionFour data={data} />
        </section>

        {/* Business Application */}
        <section
          className="section-5"
          id={prismicMenu.data.link_three.text.replace(" ", "-")}
        >
          <SectionFive
            handleOnClick={() => {
              this.openModal();
              this.setState({ videoToPlay: "old" });
            }}
            data={data}
          />
        </section>

        {/* Our story */}
        <section
          className="section-6"
          id={prismicMenu.data.link_four.text.replace(" ", "-")}
        >
          <SectionSix data={data} />
        </section>

        {/* The Team */}
        <section
          className="section-7"
        >
          <SectionSeven data={data} />
        </section>

        <section className="section-8">
          <div className="form-container">
            <h3>{data.section_eight_contact_form_title.text}</h3>
            <a
              className="submit"
              href={`mailto:${
                data.section_eight_contact_form_email_address.text
              }`}
            >
              {data.section_eight_contact_form_button_text.text}
            </a>
            <p style={{ paddingBottom: '15px' }}>Contact us at hello@swidch.com</p>
            <p>{data.contact_form_address.text}</p>
          </div>
        </section>

        <Footer
          data={prismicFooter.data}
          menu={prismicMenu.data}
        />
        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          contentLabel="Video"
          style={customStyles}
        >
          <button onClick={this.closeModal} className="close-modal-btn">
            close
          </button>
          {this.state.videoToPlay === "old" ? (
            <video ref={this.video}>
              <source src={Video} type="video/mp4" />
            </video>
          ) : (
            <video ref={this.newVideo}>
              <source src={newVideo} type="video/mp4" />
            </video>
          )}
        </Modal>
      </Fragment>
    );
  }
}

Modal.setAppElement("#___gatsby");

export default Index;

export const pageQuery = graphql`
  query {
    prismicBlogHome {
      uid
      id
      data {
        hero_image {
          url
          alt
        }
        hero_logo {
          url
          alt
        }
        hero_button_text {
          text
        }
        hero_button_2_text {
          text
        }
        section_2_panel_1_title {
          text
        }
        section_2_panel_1_copy {
          text
        }
        section_2_panel_1_image {
          url
          alt
        }
        section_2_panel_2_title {
          text
        }
        section_2_panel_2_copy {
          text
        }
        section_2_panel_2_image {
          url
          alt
        }
        section_2_panel_3_title {
          text
        }
        section_2_panel_3_copy {
          text
        }
        section_2_panel_3_image {
          url
          alt
        }
        section_title {
          text
        }
        block_one_heading {
          text
        }
        block_one_copy {
          text
        }
        block_one_image_right {
          url
          alt
        }
        block_two_heading {
          text
        }
        block_two_copy {
          text
        }
        block_two_image {
          url
          alt
        }
        block_three_heading {
          text
        }
        block_three_copy {
          text
        }
        block_three_image {
          url
          alt
        }
        block_four_heading {
          text
        }
        block_four_copy {
          text
        }
        block_four_image {
          url
          alt
        }
        section_4_title {
          text
        }
        sub_title {
          text
        }
        sub_title_2 {
          text
        }
        section_five_title {
          text
        }
        section_five_block_one_heading {
          text
        }
        section_five_block_one_copy {
          html
        }
        section_five_block_one_image {
          url
          alt
        }
        section_five_block_two_heading {
          text
        }
        section_five_block_two_copy {
          html
        }
        section_five_block_two_image {
          url
          alt
        }
        section_five_block_three_heading {
          text
        }
        section_five_block_three_copy {
          html
        }
        section_five_block_three_image {
          url
          alt
        }
        banners_carousel {
          banner_image {
            url
            alt
          }
          banner_title {
            text
          }
          banner_copy {
            text
          }
        }
        lower_carousel {
          banner_image {
            url
            alt
          }
          banner_title {
            text
          }
          banner_copy {
            text
          }
        }
        sub_title_3 {
          text
        }
        single_banner_image {
          url
          alt
        }
        single_banner_title {
          text
        }
        single_banner_copy {
          text
        }
        section_seven_team_title {
          text
        }
        section_seven_team_member {
          section_seven_team_member_image {
            alt
            url
          }
          section_seven_team_member_description {
            text
          }
          section_seven_team_member_name {
            text
          }
          section_seven_team_member_job_title {
            text
          }
          swidch_employee
        }
        section_eight_contact_form_title {
          text
        }
        section_eight_contact_form_email_address {
          text
        }
        section_eight_contact_form_button_text {
          text
        }
        contact_form_address {
          text
        }
      }
    }
    prismicMenu {
      id
      lang
      data {
        link_one {
          text
        }
        link_two {
          text
        }
        link_three {
          text
        }
        link_four {
          text
        }
      }
    }
    prismicFooter {
      id
      data {
        social_link_1_text {
          text
        }
        social_link_1 {
          url
        }
        social_link_1_logo {
          url
        }
        social_link_2_text {
          text
        }
        social_link_2 {
          url
        }
        social_link_2_logo {
          url
        }
        social_link_3_text {
          text
        }
        social_link_3 {
          url
        }
        social_link_3_logo {
          url
        }
        social_link_4_text {
          text
        }
        social_link_4 {
          url
        }
        social_link_4_logo {
          url
        }
        footerlinks {
          footer_link_text {
            html
            text
          }
          footer_link {
            link_type
            url
            target
          }
        }
        footer_logo {
          url
          alt
        }
      }
    }
  }
`;
