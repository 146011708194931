import React from "react";

import FlipCard from "../components/flipCard";

const SectionTwo = (props) => {
  const { data } = props;

  return (
    <div className="flip-card-container">
      <div className="left-content">
        <FlipCard 
          title={data.section_2_panel_1_title.text}
          copy={data.section_2_panel_1_copy.text}
          imageSrc={data.section_2_panel_1_image.url}
        />
        <FlipCard 
          title={data.section_2_panel_2_title.text}
          copy={data.section_2_panel_2_copy.text}
          imageSrc={data.section_2_panel_2_image.url}
        />
      </div>
      <div className="right-content">
        <FlipCard 
          title={data.section_2_panel_3_title.text}
          copy={data.section_2_panel_3_copy.text}
          imageSrc={data.section_2_panel_3_image.url}
        />
      </div>
    </div>
  )
}
  
export default SectionTwo;
