import React, { Fragment } from "react";

import Embed from "../components/embed";

const SectionFive = props => {
  const { data, handleOnClick } = props;

  return (
    <Fragment>
      <h2 className="align-center">{data.section_five_title.text}</h2>

      <div className="application">
        <div className="application-image">
          <img
            src={data.section_five_block_one_image.url}
            alt={data.section_five_block_one_image.alt}
          />
        </div>
        <div className="application-copy-container">
          <div className="application-copy">
            <h3>{data.section_five_block_one_heading.text}</h3>
            <Embed data={data.section_five_block_one_copy} />
            <button onClick={handleOnClick}>Payment Application</button>
          </div>
        </div>
      </div>

      <div className="application application--right">
        <div className="application-copy-container">
          <div className="application-copy">
            <h3>{data.section_five_block_two_heading.text}</h3>
            <Embed data={data.section_five_block_two_copy} />
          </div>
        </div>
        <div className="application-image">
          <img
            src={data.section_five_block_two_image.url}
            alt={data.section_five_block_two_image.alt}
          />
        </div>
      </div>

      <div className="application">
        <div className="application-image">
          <img
            src={data.section_five_block_three_image.url}
            alt={data.section_five_block_three_image.alt}
          />
        </div>
        <div className="application-copy-container">
          <div className="application-copy">
            <h3>{data.section_five_block_three_heading.text}</h3>
            <Embed data={data.section_five_block_three_copy} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SectionFive;
