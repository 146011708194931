import React, { useState } from "react";

const FlipCard = props => {
  const [isFlipped, setIsFlipped] = useState(false);
  const { title, copy, imageSrc } = props;

  const toggleClass = () => setIsFlipped(!isFlipped);

  const styles = {
    backgroundImage: `url('${imageSrc}')`,
    backgroundSize: 'cover'
  }

  return (
    <div 
      className="panel-container"
      onClick={toggleClass} 
      style={styles}
    >
      <div>
        <h3>{title}</h3>
        <p>{copy}</p>
      </div>
    </div>
  )
}

export default FlipCard
