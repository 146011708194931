import React, { Fragment } from "react";

import Slider from "react-slick";

const settings = {
  dots: true,
  arrows: false,
  appendDots: dots => (
    <Fragment>
      <div className="info-dots-container">
        <div className="info-dots">
          <ul className="slick-dots" style={{ display: "block" }}> {dots} </ul>
        </div>
      </div>
    </Fragment>
  ),
}

const SectionSeven = (props) => {
  const { data } = props;

  return (
    <Fragment>
      <h2 className="align-center">{data.section_seven_team_title.text}</h2>

      <div className="slider-container info-carousel">
        <Slider {...settings}>
          {data.section_seven_team_member.map((item, index) => {
            return (
              <div key={`team-${index}`}>
                <div className="info-slide">
                  <div className="row">
                    <div>
                      <p>“{item.section_seven_team_member_description.text}”</p>
                      <strong>{item.section_seven_team_member_name.text}</strong>
                      <span>{item.section_seven_team_member_job_title.text}</span>
                    </div>
                    <div>
                      <img src={item.section_seven_team_member_image.url} alt={item.section_seven_team_member_image.alt} />
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </Slider>
      </div>
    </Fragment>
  )
}
  
export default SectionSeven;
