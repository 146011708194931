import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

const Menu = props => {
  const { menuItems } = props;

  return (
    <nav>
      <ul>
        {Object.keys(menuItems).map((item, index) => {
          const linkText = menuItems[item].text;
          const link = linkText.replace(" ", "-");

          return linkText && (
            <li key={`menu-item-${index}`}>
              <AnchorLink href={`#${link}`}>{linkText}</AnchorLink>
            </li>
          )
        })}
      </ul>
    </nav>
  );
};

export default Menu;
