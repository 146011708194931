import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import termsOfUsePDF from "../assets/TERMS OF USE_swIDch_Final.pdf";
import privacyNoticePDF from "../assets/Privacy Notice_swIdch_Final.pdf";

const Footer = props => {
  const { data, menu } = props;

  return (
    <footer>
      <nav>
        <ul>
          {Object.keys(menu).map((item, index) => {
            const linkText = menu[item].text;
            const link = linkText.replace(" ", "-");

            return linkText && (
              <li key={`menu-item-${index}`}>
                <AnchorLink href={`#${link}`}>{linkText}</AnchorLink>
              </li>
            )
          })}
        </ul>
      </nav>
      <ul className="document-links">
        <li><a target="_blank" href={termsOfUsePDF}>Terms of Use</a></li>
        <li><a target="_blank" href={privacyNoticePDF}>Privacy Notice</a></li>
      </ul>
      <div className="lower-footer">
        <div className="social-links">
          <ul>
            <li
              style={{
                backgroundImage: `url('${data.social_link_1_logo.url}')`
              }}
            >
              <a href={data.social_link_1.url}>
                {data.social_link_1_text.text}
              </a>
            </li>
            <li
              style={{
                backgroundImage: `url('${data.social_link_2_logo.url}')`
              }}
            >
              <a href={data.social_link_2.url}>
                {data.social_link_2_text.text}
              </a>
            </li>
            <li
              style={{
                backgroundImage: `url('${data.social_link_3_logo.url}')`
              }}
            >
              <a href={data.social_link_3.url}>
                {data.social_link_3_text.text}
              </a>
            </li>
            <li
              style={{
                backgroundImage: `url('${data.social_link_4_logo.url}')`
              }}
            >
              <a href={data.social_link_4.url}>
                {data.social_link_4_text.text}
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-logo-container">
          <img src={data.footer_logo.url} alt={data.footer_logo.alt} />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
