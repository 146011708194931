import React, { Fragment } from "react";
import Slider from "react-slick";

import Banner from "../components/banner";

const SectionFour = (props) => {
  const { data } = props;
  const banners = data.banners_carousel;
  const secondCaroouselBanners = data.lower_carousel;
  const settings = {
    dots: true,
    arrows: false,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '0px',
        }
      }
    ]
  };

  const secondCarousel = {
    dots: false,
    arrows: false,
    infinte: false,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: true,
          infinte: true,
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '0px',
        }
      }
    ]
  }

  return (
    <Fragment>
      <h2 className="align-center">{data.section_4_title.text}</h2>
      <h3 className="align-center sub-section color-orange">{data.sub_title.text}</h3>
      <div className="slider-container banner-carousel">
        <Slider {...settings}>
          {banners.map((banner, index) => { 
            const wrapperClass = index % 2 === 0 ? "left" : "right";

            return (
              <div key={`carousel-banner-${index}`} className={`${wrapperClass}`}>
                <Banner 
                  bannerImage={banner.banner_image}
                  bannerCopy={banner.banner_copy.text}
                  bannerHeading={banner.banner_title.text}
                />
              </div>
            )
          })}
        </Slider>
      </div>
      <h3 className="align-center color-orange sub-section">{data.sub_title_2.text}</h3>
      <div className="multiple-cards">
        <Slider {...secondCarousel}>
          {secondCaroouselBanners.map((banner, index) => { 
            const wrapperClass = index % 2 === 0 ? "card--left" : "card--right";

            return (
              <div key={`card-${index}`} className={`card ${wrapperClass}`}>
                <Banner 
                  bannerImage={banner.banner_image}
                  bannerCopy={banner.banner_copy.text}
                  bannerHeading={banner.banner_title.text}
                />
              </div>
            )
          })}
        </Slider>
      </div>
      <h3 className="align-center color-orange sub-section">{data.sub_title_3.text}</h3>
      <div className="single-card">
        <Banner 
          bannerImage={data.single_banner_image}
          bannerCopy={data.single_banner_copy.text}
          bannerHeading={data.single_banner_title.text}
        />
      </div>
    </Fragment>
  )
}

export default SectionFour;
