import React from "react";
import Slider from "react-slick";

import logo from "../assets/logo.png";
import fullLogo from "../assets/full-logo-white.png";
import govLogo from "../assets/dfit-logo-copy.png";

const storySettings = {
  dots: true,
  arrows: false
};

const SectionSix = (props) => {
  // const { data } = props;

  return (
    <div className="our-story">
      <h2 className="align-center">Our Story</h2>
      <div className="our-story-content slider-container">
        <Slider {...storySettings}>
          <div className="our-story-slide">
            <p>swIDch began in November of 2015, with a team of 7 members who had over 100 years of combined experience in the security industry.</p>
            <p>The company started under the name SSenStone in Seoul, and their first product was a 2-way dynamic key matching algorithm.</p>
            <div className="logo">
              <img src={logo} alt="Logo" />
            </div>
          </div>
          <div className="our-story-slide">
            <p>We were part of the SparkLabs and Lotte Accelerator programs, selected as a K-Global 300 company by the government, and in 2017, won first prize at the K-Global Security Startup competition.</p>
            <p>We are both FIDO UAF and FIDO2 certified, and had many government clients such as the Constitutional Court of Korea and the Ministry of the Interior and Safety.</p>
          </div>
          <div className="our-story-slide">
            <p>In addition to large conglomerates such as Woori Financial Group, Lotte members, and Hanwha General Insurance.</p>
            <p>In 2018, we created a revolutionary Dynamic One-Time Authentication Code algorithm that allows authentication in a unidirectional environment. With this technology, we raised our Series A round.</p>
          </div>
          <div className="our-story-slide">
            <p>In 2019, we were selected by the UK Department for International Trade(DIT) to become part of the Global Entrepreneurs Programme(GEP).</p>
            <p>We established our Global Business Headquarters in London under the name swIDch Ltd.</p>
            <div className="footer-logos">
              <img className="gov" src={govLogo} alt="Logo" />
              <img className="full-logo" src={fullLogo} alt="Logo" />
            </div>
          </div>
        </Slider>
      </div>
    </div>
  )
}
  
export default SectionSix;
