import React, { Fragment } from "react";

const SectionThree = (props) => {
  const { data } = props;

  return (
    <Fragment>
      <h2 className="align-center">{data.section_title.text}</h2>

      <div className="row tech-rows tech-rows--left">
        <div className="copy-wrapper">
          <div className="copy-wrapper-center-helper">
            <h3>{data.block_one_heading.text}</h3>
            <p>{data.block_one_copy.text}</p>
          </div>
        </div>
        <div className="section-3-image-wrapper">
          <img src={data.block_one_image_right.url} alt={data.block_one_image_right.alt} />
        </div>
      </div>

      <div className="row tech-rows tech-rows--right">
        <div className="section-3-image-wrapper">
          <img src={data.block_two_image.url} alt={data.block_two_image.alt} />
        </div>
        <div className="copy-wrapper copy-wrapper--right">
          <div className="copy-wrapper-center-helper">
            <h3>{data.block_two_heading.text}</h3>
            <p>{data.block_two_copy.text}</p>
          </div>
        </div>
      </div>

      <div className="row tech-rows tech-rows--left">
        <div className="copy-wrapper">
          <div className="copy-wrapper-center-helper">
            <h3>{data.block_three_heading.text}</h3>
            <p>{data.block_three_copy.text}</p>
          </div>
        </div>
        <div className="section-3-image-wrapper">
          <img src={data.block_three_image.url} alt={data.block_three_image.alt} />
        </div>
      </div>

      <div className="row tech-rows tech-rows--right">
        <div className="section-3-image-wrapper">
          <img src={data.block_four_image.url} alt={data.block_four_image.alt} />
        </div>
        <div className="copy-wrapper copy-wrapper--right">
          <div className="copy-wrapper-center-helper">
            <h3>{data.block_four_heading.text}</h3>
            <p>{data.block_four_copy.text}</p>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default SectionThree;
